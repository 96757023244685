import "../css/style.scss";
import "../../shared/setup";

// import "intl-tel-input/build/js/utils.js"
import lazyLoadFonts from "../../shared/lib/lazyLoadFonts";
import loadPageLogic from "../../celltrack/js/pages/pages";

lazyLoadFonts([
    "https://fonts.googleapis.com/css?family=Open+Sans:600|Source+Sans+Pro:600,700|Titillium+Web:300,400,700"
]);

$("#down2").on("click", () => {
    $("#nav").toggleClass("navbar-fixed-top");
});

$("#firstcard").on("click", () => {
    window.location.href = "/track";
});

$("#secondcard").on("click", () => {
    window.location.href = "/track-location";
});

$("#thirdcard").on("click", () => {
    window.location.href = "/sms";
});

$("#fourthcard").on("click", () => {
    window.location.href = "/whatsapp";
});

loadPageLogic();
